
.NoDataComponent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #fff, $alpha: 0.99);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;

  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

.night-mode {
  
  .NoDataComponent {
    background-color: rgba($color: #212B36, $alpha: 0.99);
  }
}