.Header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #f0f3fa ;

  .right-side {
    display: flex;
    align-items: center;

    .button {
      background-color: #d9d9d9;
    }
  }
}

.night-mode {
  .Header {
    box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.2);
  }
}
