@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.Alerts {
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 1000;
  max-width: 60%;

  .alert {
    margin-bottom: 15px;
  }

  .shake-animation {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  }
}