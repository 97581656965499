@media screen and (min-width: 1024px) {
  .AuthLayout {
    width: 100%;
    height: 100vh;
    background-color: #eff3fb;

    .header {
      width: 100%;
      height: 70px;
      display: flex;
      padding: 0 30px;
      align-items: center;
      background-color: #fff;
      justify-content: space-between;

      img {
        width: 100px;
        height: 100px;
        cursor: pointer;
      }

      .secondary-btn {
        width: 145px;
        height: 40px;
        border-radius: 10px;
        background-color: #4867b4;
        color: #fff;
      }
    }

    .right-side {
      flex: 1;
      height: 100%;
      margin-left: 40px;
    }

    .left-side {
      width: 50%;
      height: 91vh;
      // position: relative;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .AuthLayout {
    width: 100%;
    height: 100vh;
    background-color: #eff3fb;

    .header {
      width: 100%;
      height: 70px;
      display: flex;
      padding: 0 30px;
      align-items: center;
      background-color: #fff;
      justify-content: space-between;

      img {
        width: 100px;
        height: 100px;
        cursor: pointer;
      }

      .secondary-btn {
        width: 145px;
        height: 40px;
        border-radius: 10px;
        background-color: #4867b4;
        color: #fff;
      }
    }

    .right-side {
      flex: 1;
      height: 100%;
      margin-left: 40px;
    }

    .left-side {
      width: 50%;
      height: 91vh;
      // position: relative;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .AuthLayout {
    width: 100%;
    height: 100vh;
    background-color: #eff3fb;

    .header {
      width: 100%;
      height: 70px;
      display: flex;
      padding: 0 30px;
      align-items: center;
      background-color: #fff;
      justify-content: space-between;

      img {
        width: 100px;
        height: 100px;
        cursor: pointer;
      }

      .secondary-btn {
        width: 145px;
        height: 40px;
        border-radius: 10px;
        background-color: #4867b4;
        color: #fff;
      }
    }

    .right-side {
      flex: 1;
      height: 100%;
      margin-left: 40px;
    }

    .left-side {
      width: 50%;
      height: 91vh;
      display: none;
      // position: relative;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
