

.FullPageLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #fff, $alpha: 0.99);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.night-mode {

  .FullPageLoader {
    background-color: rgba($color: #212B36, $alpha: 0.99);
  }
}