
.ActCreate {
  padding: 20px;
  display: flex;
  flex-direction: column;
  
  .card-title {
    margin-bottom: 25px;
  }

  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 20px;
  }

}

.modal-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

 .modal-card {
  width: 600px;
  padding: 30px;
}

.modal-btns-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;  
}
 

.ProductsList {

  width: 100%;

  .table-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
  }
}
.products__table{
  min-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  display: table;
}
.products__tbody, .products__thead {
  display: table-row-group;
}
.products__tr {
  display: table-row;
}
.products__th {
  display: table-cell;
  font: 1.6rem ;
  color: #000;
  background: #c4c4c4;
  border: 1px solid #3c3c3c;
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
.products__td {
  display: table-cell;
  position: relative;
  font: 2.1rem ;
  vertical-align: middle;
  border: 1px solid #3c3c3c;
  height: 50px;
  text-align: center;
}


input[type="file"]{
  display: none;
}