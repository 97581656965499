@media screen and (min-width: 1024px) {
  .LoginPage {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sign-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -20px;

      .btn {
        width: 100%;
        color: #4867b4;
        margin-top: 20px;
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #4867b4;

        &:disabled {
          background-color: #fff;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }

    .box {
      width: 500px;
      height: 490px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 50px 0;
      flex-direction: column;
      background-color: white;
      border-radius: 16px;

      .title {
        font-weight: 700;
        font-size: 24px;
        color: #1e2126;
      }

      .tab {
        padding: 0 27px;
        display: flex;
        margin-bottom: 25px;
        border-radius: 8px;
        align-items: center;
        border: 1px solid #7d91a9;
      }

      .login-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn {
          width: 100%;
          color: #4867b4;
          margin-top: 30px;
          border-radius: 10px;
          background-color: #fff;
          border: 1px solid #4867b4;

          &:disabled {
            background-color: #fff;
            color: #ddd;
            border: 1px solid #ddd;
          }
        }
      }
    }

    .title {
      text-align: center;
      margin-bottom: 20px;
      font-size: 30px;
    }

    .checkbox-area {
      margin-bottom: 5px;
    }

    .bottom-text {
      margin-top: 20px;
      font-weight: 500;
      font-size: 16px;
      color: #4867b4;
      text-align: center;
      cursor: pointer;
    }

    .btns-row {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 20px;

      .btn {
        flex: 1;
        margin-right: 25px;
        border-radius: 10px;
        background-color: #fff;
        color: #4867b4;
        border: 1px solid #4867b4;

        &.secondary-btn {
          background-color: #4867b4;
          color: #fff;
        }

        &:disabled {
          background-color: #fff;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .LoginPage {
    width: 100%;
    height: 100%;
    // padding: 30px 20px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .sign-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn {
        width: 100%;
        color: #4867b4;
        margin-top: 20px;
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #4867b4;

        &:disabled {
          background-color: #fff;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }

    .box {
      width: 500px;
      height: 490px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 50px 0;
      flex-direction: column;
      background-color: white;
      border-radius: 16px;

      .title {
        font-weight: 700;
        font-size: 24px;
        color: #1e2126;
      }

      .tab {
        padding: 0 16px;
        display: flex;
        margin-bottom: 25px;
        border-radius: 8px;
        margin-right: 20px;
        align-items: center;
        border: 1px solid #7d91a9;
      }

      .login-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn {
          width: 100%;
          color: #4867b4;
          margin-top: 30px;
          border-radius: 10px;
          background-color: #fff;
          border: 1px solid #4867b4;

          &:disabled {
            background-color: #fff;
            color: #ddd;
            border: 1px solid #ddd;
          }
        }
      }
    }

    .title {
      text-align: center;
      margin-bottom: 20px;
      font-size: 30px;
    }

    .checkbox-area {
      margin-bottom: 5px;
    }

    .bottom-text {
      margin-top: 20px;
      font-weight: 500;
      font-size: 16px;
      color: #4867b4;
      text-align: center;
      cursor: pointer;
    }

    .btns-row {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 20px;

      .btn {
        flex: 1;
        margin-right: 25px;
        border-radius: 10px;
        background-color: #fff;
        color: #4867b4;
        border: 1px solid #4867b4;

        &.secondary-btn {
          background-color: #4867b4;
          color: #fff;
        }

        &:disabled {
          background-color: #fff;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .LoginPage {
    width: 100%;
    height: 100%;
    // padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sign-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn {
        width: 100%;
        color: #4867b4;
        margin-top: 20px;
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #4867b4;

        &:disabled {
          background-color: #fff;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }

    .box {
      width: 500px;
      height: 490px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 150px 0;
      flex-direction: column;
      background-color: white;
      border-radius: 16px;

      .title {
        font-weight: 700;
        font-size: 24px;
        color: #1e2126;
      }

      .tab {
        padding: 0 16px;
        display: flex;
        margin-bottom: 25px;
        border-radius: 8px;
        margin-right: 20px;
        align-items: center;
        border: 1px solid #7d91a9;
      }

      .login-box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn {
          width: 100%;
          color: #4867b4;
          margin-top: 30px;
          border-radius: 10px;
          background-color: #fff;
          border: 1px solid #4867b4;

          &:disabled {
            background-color: #fff;
            color: #ddd;
            border: 1px solid #ddd;
          }
        }
      }
    }

    .title {
      text-align: center;
      margin-bottom: 20px;
      font-size: 30px;
    }

    .checkbox-area {
      margin-bottom: 5px;
    }

    .bottom-text {
      margin-top: 20px;
      font-weight: 500;
      font-size: 16px;
      color: #4867b4;
      text-align: center;
      cursor: pointer;
    }

    .btns-row {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 20px;

      .btn {
        flex: 1;
        margin-right: 25px;
        border-radius: 10px;
        background-color: #fff;
        color: #4867b4;
        border: 1px solid #4867b4;

        &.secondary-btn {
          background-color: #4867b4;
          color: #fff;
        }

        &:disabled {
          background-color: #fff;
          color: #ddd;
          border: 1px solid #ddd;
        }
      }
    }
  }
}
