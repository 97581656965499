.factura_item {
    width: 280px;
    border-radius: 20px;
    min-height: 100px;
    padding: 10px 5px 10px 5px;
}
.title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.title h1 {
    font-size: 20px;
    text-transform: uppercase;
}
.identifikatsiya {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
}
