
.ActCreate {
  padding: 20px;
  display: flex;
  flex-direction: column;
  
  .card-title {
    margin-bottom: 25px;
  }

  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 20px;
  }

}

.modal-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

 .modal-card {
  width: 600px;
  padding: 30px;
}

.modal-btns-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;  
}
 

.ProductsList {
  width: 100%;
}
.table-footer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}

.vertical_line {
  width: 1px;
  height: 50px;
  background-color: 676769;
}

