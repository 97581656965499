.MainLayout {
  width: 100%;
  min-height: 100vh;
  display: flex;

  .content-side {
    flex: 1;
    position: relative;
    height: 100vh;
    background: #E3F2FD;
    background: linear-gradient(to bottom, #F0F3F9, #F0F3F9);
    overflow: auto;
  }
}

.night-mode {
  .MainLayout {
  
    .content-side {
      background: #161C24;
      background: linear-gradient(to top, #161C24, #161C24);
    }
  }
}