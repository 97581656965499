.TtnCreate {
  padding: 20px;

  .card-title {
    margin-bottom: 25px;
    width: 100%;
  }
  .card-title-box {
    margin-top: 25px;
    width: 100%;
  }
}