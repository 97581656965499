

.MyProducts {

  .searchBlock {
    display: flex;
    width: 100%;
    
    .addBtn {
      margin-left: 30px;
      padding: 0 50px;
    }

  }
}