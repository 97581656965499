
.ThemeSwitcher {

	input {
		display: none;

		+ label {
			display: block;
			width: 50px;
			height: 36px;
			margin-right: 10px;
			border-radius: 50px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
			border: 2px solid transparent;
		
			&:hover {
				border: 2px solid #3f68ba;
			}
	
			.day, .night {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition-duration: 0.3s;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					height: 25px;
				}
			}
	
			.day {
				// background-color: #fff;
			}
	
			.night {
				// background-color: #161C24;
				transform: translateY(100%);
			}

	}

	&:checked {
		
		+label {
			
			.day {
				transform: translateY(-100%);
			}

			.night {
				transform: translateY(0);
			}
		}
	}

	}
}
